<template>
  <div class="view pa24">
    <div>
      <!-- <el-input v-model="query.companyId" placeholder=""></el-input> -->
      <el-select v-model="query.receival" placeholder="请选择接单状态">
        <el-option label="全部" value=""></el-option>
        <el-option label="未确认" :value="0"></el-option>
        <el-option label="已接单" :value="1"></el-option>
      </el-select>
      <el-button type="primary" style="margin-left: 10px" @click="getList">搜索</el-button>
    </div>
    <commonTable
      :tableData="tableData"
      :loading="loading"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
      :currentPage="currentPage"
      :total="total"
    >
      <template v-slot:table>
        <el-table-column align="center" type="index" label="序号" />
        <el-table-column prop="pstewardCompanyName" align="center" label="管家" />
        <el-table-column prop="company_name" align="center" label="服务公司" />
        <el-table-column prop="receival"  align="center" label="接单" >
          <template slot-scope="{row}">
            {{row.receival ? '已接单' : '未确认'}}
          </template>
        </el-table-column>
        <el-table-column prop="payTime" align="center" label="下单时间" />
        <el-table-column prop="endTime" align="center" label="服务结束时间" />
        <el-table-column prop="name" align="center" label="服务标准" />
        <el-table-column prop="phone" align="center" label="账号" />
        <el-table-column prop="host" align="center" label="登录域名" />
        <el-table-column align="center" label="操作" width="60">
          <template slot-scope="scope">
            <el-button v-if="!scope.row.receival" type="text" @click="handleReceival(scope.row)">接单</el-button>
          </template>
        </el-table-column>
      </template>
    </commonTable>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import {getList, setReceival} from '@/api/steward.js'
import { fetchCompany } from "@/api/companyManage.js";
let ac;
export default {
  data() {
    return {
      query: {},
      tableData: [],
      currentPage: 1, //当前页
      pageSize: 10, //显示条数
      loading: false, //表格加载
      total: 0, //总条数
    };
  },
  components: {
    commonTable,
  },
  created() {
    fetchCompany().then(res => {
      this.companyId = res.data.companyId
      this.getList();
    })
  },
  methods: {
    async getList() {

      let data = {
        pageSize: this.pageSize,
        page: this.currentPage,
        companyId: this.companyId,
        ...this.query
      };
      try {
        this.loading = true;
        const res = await getList(data);
        
        this.loading = false;
        this.tableData = res.data.dataList;
        this.total = res.data.total
      } catch (error) {
        this.loading = false;
      }
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getList();
    },
    async handleReceival(row) {
      await this.$confirm('确认接单吗？', '提示')
      await setReceival({id: row.id})
      this.getList()
    }
  },
};
</script>

<style lang="scss" scoped>
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 78px;
  height: 78px;
  line-height: 180px;
  text-align: center;
}
/deep/ .avatar-coverImg .el-upload--text {
  width: 100px;
  height: 100px;
}
/deep/ .avatar-coverImg .avatar-uploader-icon {
  line-height: 100px;
}
.select-goods-list {
  margin-bottom: 20px;
  border-radius: 40px;
  box-sizing: border-box;
  padding: 0 20px;
  background: #ffffff;
  transition: all 0.4s;
}
.select-goods-list:hover {
  background: #f5f5f6;
  transition: all 0.4s;
  cursor: pointer;
}
/deep/ .disabled .el-upload--picture-card {
  display: none;
}
</style>
